<template>
  <vxe-modal
    v-model="takeoverVisible"
    width="40%"
    height="auto"
    :title="title"
    @close="handleClose"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="{ top: '30%', left: '30%' }"
    remember
    transfer
  >
    <div class="work-order-container">
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="介入类型" prop="takeoverType">
          <a-select placeholder="请选择介入类型" v-model="form.takeoverType">
            <a-select-option v-for="(d, index) in takeoverTypeOptions" :key="index" :value="d.dictValue">{{
              d.dictLabel
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item class="form-item" label="人工备注">
          <a-textarea v-model="form.remark" placeholder="人工备注" auto-size />
        </a-form-model-item>
        <a-form-model-item class="form-item" label="记录人员" prop="operator">
          {{ form.operator }}
        </a-form-model-item>
        <div class="btns">
          <a-button v-if="!isBrowse" type="primary" @click="handleSubmit">保存</a-button>
          <a-button @click="takeoverVisible=false">取消</a-button>
        </div>
      </a-form-model>
    </div>
  </vxe-modal>
</template>
<script>
import { mapState } from 'vuex'
 import { updateTakeOver } from '@/api/iot/takeover'
export default {
  props: {
    takeoverTypeOptions: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      title: '人工介入记录分类',
      takeoverVisible: false,
      form: {
        takeoverType: '',
        operator: '',
        remark: ''
      },
      rules: {
        takeoverType: [{ required: true, message: '介入类型不能为空', trigger: 'change' }],
        remark: [{ required: true, message: '人工备注不能为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.info
    })
  },
  created() {
  },
  watch: {},

  methods: {

    handleClose() {
      this.takeoverVisible = false
    },

    handleUpdate(item) {
       this.form = {
         takeoverType: '',
         operator: '',
         remark: ''
       }
       this.form = Object.assign(this.form, item)
       if (item.takeoverType >= 0) {
        console.log('takeoverType', item.takeoverType)
         if (item.takeoverType === null) {
            item.takeoverType = ''
         }
         this.form.takeoverType = item.takeoverType + ''
       }
       this.form.operator = this.userInfo.userName
       this.takeoverVisible = true
       this.$refs.form && this.$refs.form.resetFields()
    },
    handleSubmit() {
      console.log('form', this.form)
      this.form.takeoverType = this.form.takeoverType + ''
      this.form.status = 1
      this.$refs.form.validate((valid) => {
        if (valid) {
            updateTakeOver(this.form).then((response) => {
              this.$message.success('保存成功', 3)
              this.$emit('ok')
              this.takeoverVisible = false
            })
        }
      })
    }
  }
}
</script>
<style lang="less">
.work-order-container {
  .ant-form-item {
    display: flex;
    margin-bottom: 0;
    .ant-form-item-label {
      flex: 0 0 105px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .btns {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    button:first-child {
      margin-right: 10px;
    }
  }
  .row {
    display: flex;
    line-height: 24px;
    .column {
      flex: 1;
      display: flex;
      align-items: center;
      .direction {
        display: flex;
        align-items: center;
      }
    }
  }
  .form-item {
    margin-top: 10px;
  }
}
</style>
