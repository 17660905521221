var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      attrs: {
        width: "40%",
        height: "auto",
        title: _vm.title,
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        "esc-closable": true,
        position: { top: "30%", left: "30%" },
        remember: "",
        transfer: "",
      },
      on: { close: _vm.handleClose },
      model: {
        value: _vm.takeoverVisible,
        callback: function ($$v) {
          _vm.takeoverVisible = $$v
        },
        expression: "takeoverVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "work-order-container" },
        [
          _c(
            "a-form-model",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "介入类型", prop: "takeoverType" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择介入类型" },
                      model: {
                        value: _vm.form.takeoverType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "takeoverType", $$v)
                        },
                        expression: "form.takeoverType",
                      },
                    },
                    _vm._l(_vm.takeoverTypeOptions, function (d, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { staticClass: "form-item", attrs: { label: "人工备注" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "人工备注", "auto-size": "" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "form-item",
                  attrs: { label: "记录人员", prop: "operator" },
                },
                [_vm._v(" " + _vm._s(_vm.form.operator) + " ")]
              ),
              _c(
                "div",
                { staticClass: "btns" },
                [
                  !_vm.isBrowse
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.takeoverVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }