import request from '@/utils/request'
// 查询人工介入记录列表
export function listTakeOver (query) {
  return request({
    url: '/iot/takeover/list',
    method: 'get',
    params: query
  })
}

// 修改人工介入记录
export function updateTakeOver(data) {
  return request({
    url: '/iot/takeover',
    method: 'put',
    data: data
  })
}
